@font-face {
    font-family: "gilroy-extrabold";
    src: url("../../public/assets/fonts/gilroy-extrabold-webfont.woff2") format("woff2"),
      url("../../public/assets/fonts/gilroy-extrabold-webfont.woff") format("woff");
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "gilroy-light";
    src: url("../../public/assets/fonts/gilroy-light-webfont.woff2") format("woff2"),
      url("../../public/assets/fonts/gilroy-light-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
  }
  
  
  // Customize Bootstrap Variables
  
  $font-family-sans-serif: "gilroy-light", system-ui, -apple-system, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  $headings-font-family: "gilroy-extrabold", system-ui, -apple-system, "Segoe UI",
    Roboto, "Helvetica Neue", Arial;
  
  $primary: #004884;
  $secondary: $primary;
  $info: #009ee3;
  
  $body-color: $primary;
  $body-bg: #fff;
  
  $link-decoration: none;
  
  $input-placeholder-color: rgba(0, 72, 132, .5);
  
  $accordion-icon-color: $info;
  $accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='11' viewBox='0 0 18 11' fill='#{$accordion-icon-color}'><path d='M17.5541 0.384352C17.3092 0.139502 16.9771 0.00195308 16.6307 0.00195307C16.2844 0.00195305 15.9523 0.139502 15.7073 0.384352L9.24231 6.84938L2.77728 0.384351C2.53095 0.146441 2.20104 0.0147959 1.85859 0.0177717C1.51614 0.0207474 1.18856 0.158106 0.946407 0.400261C0.704252 0.642416 0.566897 0.969994 0.563921 1.31244C0.560945 1.65489 0.692589 1.9848 0.9305 2.23113L8.31892 9.61955C8.56385 9.8644 8.89599 10.002 9.24231 10.002C9.58863 10.002 9.92078 9.8644 10.1657 9.61955L17.5541 2.23113C17.799 1.98621 17.9365 1.65406 17.9365 1.30774C17.9365 0.961419 17.799 0.629276 17.5541 0.384352Z'/></svg>");
  $accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' style='transform: rotate(-180deg);' width='18' height='11' viewBox='0 0 18 11' fill='#{$accordion-icon-color}'><path d='M17.5541 0.384352C17.3092 0.139502 16.9771 0.00195308 16.6307 0.00195307C16.2844 0.00195305 15.9523 0.139502 15.7073 0.384352L9.24231 6.84938L2.77728 0.384351C2.53095 0.146441 2.20104 0.0147959 1.85859 0.0177717C1.51614 0.0207474 1.18856 0.158106 0.946407 0.400261C0.704252 0.642416 0.566897 0.969994 0.563921 1.31244C0.560945 1.65489 0.692589 1.9848 0.9305 2.23113L8.31892 9.61955C8.56385 9.8644 8.89599 10.002 9.24231 10.002C9.58863 10.002 9.92078 9.8644 10.1657 9.61955L17.5541 2.23113C17.799 1.98621 17.9365 1.65406 17.9365 1.30774C17.9365 0.961419 17.799 0.629276 17.5541 0.384352Z'/></svg>");
  
  $carousel-control-color: $secondary;
  $carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 21' fill='#{$carousel-control-color}'><path d='M0.458879 0.462242C0.165059 0.758305 0 1.1598 0 1.57843C0 1.99707 0.165059 2.39856 0.458879 2.69462L8.21692 10.5095L0.458879 18.3244C0.173386 18.6222 0.0154122 19.021 0.0189831 19.4349C0.022554 19.8489 0.187384 20.2449 0.47797 20.5376C0.768557 20.8303 1.16165 20.9963 1.57259 20.9999C1.98352 21.0035 2.37942 20.8444 2.67501 20.5568L11.5411 11.6257C11.8349 11.3297 12 10.9282 12 10.5095C12 10.0909 11.8349 9.68941 11.5411 9.39334L2.67501 0.462242C2.3811 0.166269 1.98253 0 1.56695 0C1.15136 0 0.752787 0.166269 0.458879 0.462242Z'/></svg>") !default;
  $carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 21' fill='#{$carousel-control-color}'><path d='M11.5411 20.5378C11.8349 20.2417 12 19.8402 12 19.4216C12 19.0029 11.8349 18.6014 11.5411 18.3054L3.78308 10.4905L11.5411 2.67556C11.8266 2.3778 11.9846 1.979 11.981 1.56505C11.9774 1.1511 11.8126 0.755125 11.522 0.462408C11.2314 0.169691 10.8384 0.00365629 10.4274 5.89903e-05C10.0165 -0.00353831 9.62058 0.155592 9.32499 0.443178L0.45888 9.37428C0.16506 9.67034 9.55371e-07 10.0718 9.18772e-07 10.4905C8.82174e-07 10.9091 0.16506 11.3106 0.458879 11.6067L9.32499 20.5378C9.6189 20.8337 10.0175 21 10.4331 21C10.8486 21 11.2472 20.8337 11.5411 20.5378Z'/></svg>") !default;
  
  $nav-link-color: $info;
  $nav-pills-link-active-bg: $info;
  
  
  // Required
  @import "bootstrap/scss/functions";
  @import "bootstrap/scss/variables";
  @import "bootstrap/scss/mixins";
  @import "bootstrap/scss/utilities";
  @import "bootstrap/scss/root";
  @import "bootstrap/scss/reboot";
  
  @import "bootstrap/scss/type";
  @import "bootstrap/scss/images";
  @import "bootstrap/scss/containers";
  @import "bootstrap/scss/grid";
  // @import "bootstrap/scss/tables";
  @import "bootstrap/scss/forms";
  @import "bootstrap/scss/buttons";
  @import "bootstrap/scss/transitions";
  @import "bootstrap/scss/dropdown";
  // @import "bootstrap/scss/button-group";
  @import "bootstrap/scss/nav";
  @import "bootstrap/scss/navbar"; // Requires nav
  // @import "bootstrap/scss/card";
  // @import "bootstrap/scss/breadcrumb";
  @import "bootstrap/scss/accordion";
  // @import "bootstrap/scss/pagination";
  // @import "bootstrap/scss/badge";
  // @import "bootstrap/scss/alert";
  // @import "bootstrap/scss/progress";
  // @import "bootstrap/scss/list-group";
  @import "bootstrap/scss/close";
  // @import "bootstrap/scss/toasts";
  @import "bootstrap/scss/modal"; // Requires transitions
  // @import "bootstrap/scss/tooltip";
  // @import "bootstrap/scss/popover";
  @import "bootstrap/scss/carousel";
  @import "bootstrap/scss/spinners";
  @import "bootstrap/scss/offcanvas"; // Requires transitions
  // @import "bootstrap/scss/placeholders";
  
  // Helpers
  @import "bootstrap/scss/helpers";
  
  // Utilities
  @import "bootstrap/scss/utilities/api";
  
  //
  // Custom styles
  //
  @import "utilities";
  
  body {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  @media (max-width: 767px) {
    .carousel-inner .carousel-item > div {
        display: none;
    }
    .carousel-inner .carousel-item > div:first-child {
        display: block;
    }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
    display: flex;
}

/* medium and up screens */
@media (min-width: 768px) {
    
    .carousel-inner .carousel-item-end.active,
    .carousel-inner .carousel-item-next {
      transform: translateX(25%);
    }
    
    .carousel-inner .carousel-item-start.active, 
    .carousel-inner .carousel-item-prev {
      transform: translateX(-25%);
    }
}

.carousel-inner .carousel-item-end,
.carousel-inner .carousel-item-start { 
  transform: translateX(0);
}

