@include media-breakpoint-up(lg) {
  .display-5 {
    font-size: 3.125rem;
    line-height: 1;
  }
  h2 {
    font-size: 3rem;
    line-height: 1;
  }
}

h6 {
  font-size: 1.25rem;
}

.btn-outline-info.text-secondary {
  border-radius: 0.625rem !important;
  padding: 0.7rem 1.6rem !important;
  &:hover {
    border-color: #d1eefa !important;
    background-color: #d1eefa !important;
  }
}

.fw-bold,
.gilroy-extrabold {
  font-family: "gilroy-extrabold";
}
.fw-light {
  font-family: "gilroy-light";
}

.vertical-align-sub {
  vertical-align: sub;
}

.top-130 {
  top: 130px !important;
}

.shadow {
  box-shadow: 0 0.75rem 1.5rem rgba(0, 72, 132, 0.15) !important;
}

.header-background {
  background-size: auto 450px, auto;
  background-repeat: no-repeat, repeat-x;
  background-position: calc(50% + 300px) 0, 0 0;
  padding-bottom: 30px;
  &-inkasso {
    background-image: url(../../public/assets/img/coin.svg),
      linear-gradient(180deg, #e4f4fc 0%, #b5e2f6 100%);
  }
  &-bonitat {
    background-image: url(../../public/assets/img/signature-background.svg),
      linear-gradient(180deg, #e4f4fc 0%, #b5e2f6 100%);
  }
  &-verlustscheine {
    background-image: url(../../public/assets/img/magnifying-glass-background.svg),
      linear-gradient(180deg, #e4f4fc 0%, #b5e2f6 100%);
  }
  &-uber-uns {
    background-image: url(../../public/assets/img/pfad.svg),
      linear-gradient(180deg, #e4f4fc 0%, #b5e2f6 100%);
  }
  &-no-bg-image {
    background: linear-gradient(180deg, #e4f4fc 0%, #b5e2f6 100%);
  }

  @include media-breakpoint-up(lg) {
    background-size: auto 150%, auto;
    &-uber-uns {
      background-size: auto 130%, auto;
    }
    &:not(.position-fixed) {
      min-height: 394px;
    }
  }
}

.header-background-index {
  background-image: url(../../public/assets/img/mountain.png),
    linear-gradient(180deg, #e4f4fc 0%, #b5e2f6 100%);
  background-position: calc(50% + 310px) 120%, left top;
  background-repeat: no-repeat, repeat;
  background-size: auto 350px, auto 100%;
  @include media-breakpoint-down(lg) {
    background-size: auto 250px, auto 100%;
  }
  padding-bottom: 180px;
}

.bg-blue-gradient {
  background: linear-gradient(
      180deg,
      rgba(102, 197, 237, 0.18) 0%,
      rgba(0, 158, 227, 0.3) 100%
    ),
    #ffffff;
}

.border-info-lighter {
  border-color: #66c5ed !important;
}

.has-bottom-arrow {
  position: relative;
  overflow: hidden;
  padding: 2rem 2rem 4rem;
  summary ~ * {
    max-height: 0;
    transition: max-height 0.4s ease-in;
  }
}

.has-bottom-arrow[open] {
  summary {
    ~ * {
      max-height: 500px;
    }
    &:after {
      transform: translateX(-50%) rotate(180deg);
    }
  }
}

.has-bottom-arrow summary::after {
  position: absolute;
  bottom: 1.5rem;
  padding: 7px;
  width: $accordion-icon-width;
  height: $accordion-icon-width;
  content: "";
  left: 50%;

  background-image: escape-svg($accordion-button-icon);
  background-repeat: no-repeat;
  background-size: $accordion-icon-width;
  background-position: center;

  color: #009ee3;
  text-align: center;
  font-size: 18px;
  display: block;
  transform: translateX(-50%) rotate(0deg);
  @include transition($accordion-icon-transition);
}

.has-bottom-arrow > summary {
  list-style: none;
}

.has-bottom-arrow > summary::-webkit-details-marker {
  display: none;
}

.rounded-4 {
  border-radius: 10px;
}
.rounded-5 {
  border-radius: 15px;
}
.mt-n-7px {
  margin-top: -7px;
}
h4 + p.mt-n-7px {
  line-height: 1.4;
}

@include media-breakpoint-up(lg) {
  .mb-negative {
    margin-bottom: -7% !important;
  }
  .middle-card-de {
    position: relative;
    top: -30px;
    &:after,
    &:before {
      left: -236px;
      content: "kein Ertrag";
      background-image: url(../../public/assets/img/left-lines.png);
      background-size: 236px 48px;
      background-position: bottom center;
      background-repeat: no-repeat;
      text-align: center;
      font-weight: 800;
      font-family: "gilroy-extrabold";
      color: $secondary;
      display: block;
      width: 236px;
      height: 68px;
      top: 58px;
      position: absolute;
    }
    &:after {
      left: 100%;
      content: "mit Ertrag";
      background-image: url(../../public/assets/img/right-lines.png);
    }
  }
  .middle-card-en {
    position: relative;
    top: -30px;
    &:after,
    &:before {
      left: -236px;
      content: "no success";
      background-image: url(../../public/assets/img/left-lines.png);
      background-size: 236px 48px;
      background-position: bottom center;
      background-repeat: no-repeat;
      text-align: center;
      font-weight: 800;
      font-family: "gilroy-extrabold";
      color: $secondary;
      display: block;
      width: 236px;
      height: 68px;
      top: 58px;
      position: absolute;
    }
    &:after {
      left: 100%;
      content: "with success";
      background-image: url(../../public/assets/img/right-lines.png);
    }
  }
}

a.text-info:not(.btn) {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}
.bg-info {
  background-color: #66c5ed !important;
}
.bg-opacity-info {
  background-color: rgba(210, 237, 250, 0.9) !important;
}
.info-lighter {
  color: #66c5ed !important;
}

.carousel-control-prev,
.carousel-control-next {
  position: static;
  border: 2px solid #66C5ED;
  border-radius: 10px;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 65px;
  height: 50px;
  opacity: 1;
  &:hover {
    border-color: #d1eefa !important;
    background-color: #d1eefa !important;
  }
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  width: 10px;
  height: 17px;
  background-size: cover;
}

@include media-breakpoint-up(lg) {
  .navbar-nav .nav-link {
    &.fw-bold {
      font-size: 22px;
      margin-top: -12px;
      padding-right: 0.75rem;
      padding-left: 0.75rem;
      &:hover {
        text-decoration: none !important;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          height: 5px;
          left: 8px;
          right: 8px;
          bottom: -5px;
          background-color: #66c5ed;
        }
      }
    }
  }
}

.nav-pills {
  .nav-link {
    border-radius: 0.625rem;
    border-width: 2px !important;
    &:hover {
      color: $info;
      background-color: rgba(102, 197, 237, 0.2);
    }
    &.active {
      color: white;
      border-color: #009ee3 !important;
      background-color: #009ee3 !important;
    }
  }
}

.list-with-download-icon {
  list-style: none;
  padding-left: 0;
  margin-bottom: 9px;
  li {
    background-image: url(../../public/assets/img/download-icon.svg);
    background-size: 20px 18px;
    background-position: left 4px;
    background-repeat: no-repeat;
    padding-left: 32px;
    padding-top: 2px;
    margin-bottom: 10px;
  }
}
.list-with-dash {
  list-style: none;
  padding-left: 0;
  li {
    margin-bottom: 16px;
    padding-left: 50px;
    position: relative;
    &:before {
      display: inline-block;
      content: "";
      top: 10px;
      left: 0;
      position: absolute;
      width: 30px;
      height: 4px;
      background-color: #66c5ed;
    }
  }
}
.form-check-input {
  border-color: $secondary;
}

.form-select,
.form-control {
  border-radius: 15px;
  border-color: $secondary;
}

#sticky-navbar {
  transition: top 0.3s ease-in-out;
  &.noscrolled {
    z-index: -1;
    top: -200px;
  }
  z-index: 1030;
  top: 0;
}

@include media-breakpoint-up(xxl) {
  .bg-blue-gradient.sticky-lg-top {
    min-width: 270px;
    max-width: 270px;
    padding: 2.5rem 1.4rem 2.2rem 1.5rem !important;
  }
}
